import React, { useEffect, useState } from 'react'
import { getHighlight } from '../Service/Api';
import { Link } from 'react-router-dom'
const Highlights = () => { 
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getHighlight();
      console.log(data);
      setData(data);
    };
    getData();
  }, []);


  const emptyArray = [
    { description: "Stay tuned for latest updates" }
  ];

  return (
    <>
      <div className="highlights">
        <marquee onMouseOver={(event) => event.currentTarget.stop()} onMouseOut={(event) => event.currentTarget.start()}>
          {data.length > 0 ? (data.map((item) => (<p> {item.description}  {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank">  <i className="bi bi-paperclip"></i></Link>)}</p>
          ))
          ) : (
            emptyArray.map((data) => (
              <p><Link to="#" target="_blank">Stay connected for more updates! <i className="bi bi-paperclip"></i></Link></p>
            ))
          )}

        </marquee>
      </div>
    </>
  )
}

export default Highlights
