import { useEffect, useState } from "react";
import { getNewsInner } from "../Service/Api";
import { Link } from 'react-router-dom' 
import LazyLoad from 'react-lazyload';
import Header from'../Component/Header';
import Footer from'../Component/Footer';   
const Notice = () => {  
       useEffect(() => {
      document.title = 'Notice -  Anand Bhawan School, Barabanki';  
  }, []); 
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const Year = `${currentYear}`;
        setSelectedYear(Year); 
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const noticeDatas = await getNewsInner();
            const noticeData = noticeDatas.filter((item) => item.category === 'Notice');
            setData(noticeData);

            const uniqueYears = Array.from(
                new Set(noticeData.flatMap((item) => {
                    if (item.fromDate) {
                        const year = new Date(item.fromDate).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);

            const uniqueMonths = Array.from(
                new Set(noticeData.flatMap((item) => {
                    if (item.fromDate) {
                        const month = new Date(item.fromDate).getMonth();
                        return [month];
                    } else {
                        return [];
                    }
                }))
            );
            const monthOptions = uniqueMonths.sort((a, b) => a - b).map(month => monthNames[month]);
            setMonthOptions(["All", ...monthOptions]);
        };
        fetchData();
    }, []);
    useEffect(() => {
        let filteredData = data;
    
        // Check if current year data is available
        const currentYearData = data.filter((item) => {
            if (item.fromDate && item.fromDate.trim() !== "") {
                const year = new Date(item.fromDate).getFullYear();
                return year === parseInt(selectedYear);
            }
            return false;
        });
    
        // If no data for the selected year, fallback to all data
        if (currentYearData.length === 0 && selectedYear !== "All") {
            filteredData = data;  // Show all data if the current year is unavailable
        } else {
            // Filter by year
            if (selectedYear !== "All") {
                filteredData = filteredData.filter((item) => {
                    if (item.fromDate && item.fromDate.trim() !== "") {
                        const year = new Date(item.fromDate).getFullYear();
                        const month = new Date(item.fromDate).getMonth();
                        return parseInt(selectedYear) === year && (selectedMonth === "All" || monthNames[month] === selectedMonth);
                    } else {
                        return false;
                    }
                });
            }
    
            // Filter by month
            if (selectedMonth !== "All") {
                filteredData = filteredData.filter((item) => {
                    if (item.fromDate && item.fromDate.trim() !== "") {
                        const month = new Date(item.fromDate).getMonth();
                        return monthNames[month] === selectedMonth;
                    } else {
                        return false;
                    }
                });
            }
    
            // Filter by search query
            if (searchQuery) {
                filteredData = filteredData.filter((item) =>
                    item.title.toLowerCase().includes(searchQuery.toLowerCase())
                );
            }
        }
    
        // Sort the filtered data by year and date in descending order
        filteredData.sort((a, b) => {
            const yearA = new Date(a.date).getFullYear();
            const yearB = new Date(b.date).getFullYear();
            if (yearA === yearB) {
                return new Date(b.date) - new Date(a.date);  // Sort by date descending if years are the same
            }
            return yearB - yearA;  // Sort by year descending
        });
    
        // Set the filtered and sorted data
        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);
    const emptyArray = [
        {title:"News Title",description:"Stay Tunned For More Updates",day:"DD",month:"MM"},
        {title:"News Title",description:"Stay Tunned For More Updates",day:"DD",month:"MM"}
       ];
    return (
        <>
        <Header/>
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>   
                    <li>Notice</li>
                </ul>
            </div> 
            <section className="innerbody">
            <div className="container">
                <div className="row tabs-dynamic">
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                        <div className="count-val">
                            <p>Total Count: {filterData.length} </p>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                        <div className="month-selection">
                            <select
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                                className="yearSelection">
                                <option value={"All"}>All</option>

                                {yearRanges.map((yr) => (
                                    <option key={yr} value={yr}>{yr}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                        <div className="month-selection">
                            <select
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                                className="monthSelect">
                                {monthOptions.map((month, index) => (
                                    <option key={index} value={month}>{month}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                        <div className="searchBox">
                            <input
                                type="text"
                                id="myInput"
                                name="name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search Here.."
                            />
                        </div>
                    </div>
                </div>
                <div className="row years year2023 showYear">
                    {filterData.length > 0 &&
                        filterData.map((item, index) => (
                            <div className="col-md-12 eventCount" key={index}>
                                <div className="event-blk">
                                    <div className="inr-event-blk">
                                        <div className="inr-event-img">
                                            <LazyLoad>
                                            {item.image.length> 0 ? ( <img src={`https://webapi.entab.info/api/image/${item.image}`} className="img-fluid" alt="ABSB" />  ) : (<img src="https://webapi.entab.info/api/image/ABSB/public/Images/news.jpg" className="img-fluid" alt="ABSB" />)}  
                      
                                            </LazyLoad>
                                        </div>
                                        <div className="inr-event-content">
                                            <h6 className="date">{item.fromDate}</h6>
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p> 
                                                {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank" className="attch"> <span> View Attachment <i className="bi bi-arrow-right"></i> </span></Link>)}
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </section>
           <Footer/>
        </>
    );
}

export default Notice;
