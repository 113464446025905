import React, { useEffect } from 'react';
import { useLocation, NavLink ,Link } from 'react-router-dom'; 
import Highlights from '../Component/Highlights'
import LazyLoad from 'react-lazyload'; 
const Header = () => { 
        const { pathname } = useLocation(); 
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [pathname]);
    return (
        <>
            <div className="header padded-sec">
                <div className='top-header'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-xl-4 col-lg-6 col-md-6 header-cont'>
                                <div className='header-contact'>
                                    <p><a href="tel: 81279 42085">  <img src="https://webapi.entab.info/api/image/ABSB/public/Images/call.gif" /> +91 81279 42085</a> <a href="https://maps.app.goo.gl/zKHJ4hLBHB7EkBuy5" target='_blank'> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/location.gif" /> Dewa road, Barabanki, pin 225001</a></p>
                                </div>
                            </div>
                            <div className='col-xl-5 col-lg-6 col-md-6 highlights'>
                                <Highlights />
                            </div>
                            <div className='col-xl-3 col-lg-hide d-flex p-0'>
                                <Link to="/ErpSoftware"><button className='header-btn'><span>School ERP Software</span></button></Link>
                                <Link to="/Tc"><button className='header-btn'><span>Transfer Certificate</span></button></Link>
                            </div>
                        </div>
                    </div>
                 </div>  
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6'>
                          <div className="logo">
                                <Link to="/">
                                     <LazyLoad><img src="https://webapi.entab.info/api/image/ABSB/public/Images/logo.png" alt="Anand Bhawan School, Barabanki" className="img-fluid" /> </LazyLoad>
                                </Link>
</div>  
                        </div>
                        <div className='col-lg-8 col-md-6'>
                            <div className="header-nav">
                                <div className='online-fee'>
                                   <Link to="https://entab.online/ABSBBK" target="_blank"> <button>Online fee payment</button></Link>
                                </div>
                                <nav className="navbar navbar-expand-lg">
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="main_nav">
                                        <ul className="navbar-nav">
                                            <li className="nav-item"> <NavLink to="/">HOME</NavLink></li>
                                            <li className="nav-item dropdown">

                                                <div className="nav-link" data-bs-toggle="dropdown">  ABOUT </div>
                                                <ul className="dropdown-menu fade-up">
                                                    <li><NavLink className="dropdown-item" to="/AboutUs">About Us</NavLink></li>
                                                    <li><NavLink className="dropdown-item" to="/EthosValue">Ethos & Value</NavLink></li>
                                                    <li><NavLink className="dropdown-item" to="/MissionAim"> Mission & Aim</NavLink></li>
                                                    <li><NavLink className="dropdown-item" to="/Gallery">Gallery </NavLink></li>
                                                    <li><NavLink className="dropdown-item" to="/TopperX"> Topper X</NavLink></li>
                                                    <li><NavLink className="dropdown-item" to="/TopperXII">Topper XII </NavLink></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" data-bs-toggle="dropdown">MESSAGES </div>
                                                <ul className="dropdown-menu fade-up">
                                                    <li><NavLink className="dropdown-item" to="/PrincipalMessage">Principal's Message </NavLink></li>
                                                    <li><NavLink className="dropdown-item" to="/DirectorMessage">  Director's Message</NavLink></li> 
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" data-bs-toggle="dropdown">GALLERY </div>
                                                <ul className="dropdown-menu fade-up">
                                                    <li><NavLink className="dropdown-item" to="/Gallery"> Gallery</NavLink></li>
                                                    <li><NavLink className="dropdown-item" to="/VideoGallery">  Video Gallery</NavLink></li> 
                                                </ul>
                                            </li>
                                            <li className="nav-item"> <NavLink to="/News">NEWS</NavLink></li>
                                            <li className="nav-item"> <NavLink to="/ContactUs">CONTACT US</NavLink></li> 
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
