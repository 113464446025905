import { useEffect, useState } from "react";
import React from 'react';
import Header from'../Component/Header';
import Footer from'../Component/Footer'; 
import { useNavigate, Link } from "react-router-dom";
import { getGalleryInner } from "../Service/Api";
import LazyLoad from 'react-lazyload';
const Gallery = () => {
  useEffect(() => {
    document.title = 'Gallery -  Anand Bhawan School, Barabanki';  
}, []); 
const navigate = useNavigate();
const [data, setData] = useState([]);
const [filterData, setFilteredData] = useState([]);
const [selectedYear, setSelectedYear] = useState("All");
const [selectedMonth, setSelectedMonth] = useState("All");
const [monthOptions, setMonthOptions] = useState(["All"]);
const [yearRanges, setYearRanges] = useState([]);
const [searchQuery, setSearchQuery] = useState("");

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// Fetch the gallery data and year/month options
useEffect(() => {
  const fetchData = async () => {
    const galleryData1 = await getGalleryInner();
    const galleryData = galleryData1.filter(item => item.category === 'PhotoGallery');
    setData(galleryData);

    const yearsSet = new Set();
    const monthsSet = new Set();

    galleryData.forEach(item => {
      if (item.date) {
        const date = new Date(item.date);
        const getmonth = date.getMonth();
        yearsSet.add(date.getFullYear());
        monthsSet.add(getmonth);
      }
    });

    const uniqueYearRanges = Array.from(yearsSet).sort((a, b) => b - a);
    setYearRanges(uniqueYearRanges);

    const monthOptions = Array.from(monthsSet).sort((a, b) => a - b).map(month => monthNames[month]);
    setMonthOptions(["All", ...monthOptions]);

    console.log("Year Ranges:", uniqueYearRanges);
    console.log("Month Options:", monthOptions);
  };

  fetchData();
}, []);

// Update filtered data based on selected filters (Year, Month, Search Query)
useEffect(() => {
  let filteredData = data;

  // If selectedYear is not "All" or selectedMonth is not "All", filter by those values
  if (selectedYear !== "All" || selectedMonth !== "All") {
    filteredData = filteredData.filter(item => {
      const itemDate = item.date?.trim();
      if (itemDate) {
        const date = new Date(itemDate);  
        const year = date.getFullYear();
        const month = date.getMonth();
        const isYearMatch = selectedYear === "All" || year === parseInt(selectedYear);
        const isMonthMatch = selectedMonth === "All" || monthNames[month] === selectedMonth;
        return isYearMatch && isMonthMatch;
      }
      return false;
    });
  }

  // If search query exists, filter by title
  if (searchQuery) {
    filteredData = filteredData.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
  }

  // Sort filtered data by date in descending order
  filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

  setFilteredData(filteredData);
}, [selectedYear, selectedMonth, searchQuery, data]);

  const handleModal = id => {
    navigate(`/SubGallery?id=${id}`);
  };

  const emptyArray = [
    {
      description: "Stay Tuned for Latest Updates",
      attachments: ["placeholder.jpg"]
    }
  ];

  return (
    <>
    <Header/>
    <div className="innerslide">
          <ul className="breadcrumb">
              <li><Link to="/"> Home</Link> </li>   
              <li>Gallery</li>
          </ul>
      </div> 
      <section className="innerbody">
        <div className="container">
          <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="count-val">
                <p>Total Categories: {filterData.length}</p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection">
                  <option value="All">All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>{yr}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="monthSelect">
                  {monthOptions.map((month, index) => (
                    <option key={index} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here.."
                />
              </div>
            </div>
          </div>
          <div className="row years showYear">
            {filterData.length > 0 ? (
              filterData.map((item, index) => { 
                const date = new Date(item.date);
                const day = date.getDate();
                const month = monthNames[date.getMonth()];
                return(
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" key={index}>
                  <div className="gal_new">
                    <article>
                    <LazyLoad> {item?.attachments.length > 0 ? (<img src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`}  alt="Gallery" className="img-fluid"/>) : (<img src="https://webapi.entab.info/api/image/ABSB/public/Images/demo.jpg" alt="Gallery Placeholder" className="img-fluid" />)} </LazyLoad>
                      <div className="gal-content">
                        <h4 className="catgry-name">{item.title}</h4>
                        <p>{item.description}</p>
                        {item.url && <Link to={item.url}>Download Photos</Link>}
                        <h6>
                          <i className="bi bi-calendar-week"></i>   {day} {month}
                          <span>
                          <a onClick={(e) => {e.preventDefault();window.open(`/SubGallery?id=${item._id}`, "_blank");}}>View More <i className="bi bi-arrow-right"></i></a>
                          
                          </span>
                        </h6>
                        <div className="total-img">
                          <i className="bi bi-card-image"></i>
                          <p>{item?.attachments.length}</p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              )}
              )
            ) : (
              emptyArray.map((data, index) => (
                <div key={index} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount">
                  <div className="gal_new">
                    <article>
                      <LazyLoad>
                        <img
                          src="https://webapi.entab.info/api/image/ABSB/public/Images/demo.jpg"
                          alt="Gallery Placeholder"
                          className="img-fluid"
                        />
                      </LazyLoad>
                      <div className="gal-content">
                        <h4 className="catgry-name">Title</h4>
                        <p>Description</p>
                        <h6>
                          <i className="bi bi-calendar-week"></i> DD MM YYYY
                          <span><Link to="/SubGallery">View More <i className="bi bi-arrow-right"></i></Link></span>
                        </h6>
                        <div className="total-img">
                          <i className="bi bi-card-image"></i>
                          <p>5</p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
       <Footer/>
    </>
  );
};

export default Gallery;
