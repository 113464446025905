import React,{useEffect} from "react"; 
import { Link } from "react-router-dom";
import Header from'../Component/Header';  
import Footer from'../Component/Footer';   
const MissionAim = () => {  
       useEffect(() => {
      document.title = 'MissionAim -  Anand Bhawan School, Barabanki';  
  }, []); 
    return (
        <>
        <Header/>
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>About</li>
                    <li>Mission & Aim</li>
                </ul>
            </div> 
            <section className="innerbody">
                 <div className="container"> 
                      <div className="row">
                           <div className="col-lg-12">
                                  <h5>Vision</h5>
                                  <div className="missionaim">
                                    <h4>We Educate to Empower</h4>
                                  </div>
                                <h5>Mission</h5>
                                <div className="missionaim">
                                <h4>Imparting quality education, <br/> We strive to build <br/> Effective, efficient, responsible <br/> Individuals to transform the Society. </h4>
                                  </div>
                                  <h5>Aims of the Institution</h5>
<p> To develop in every student those qualities that will make them feel their place worthwhile in society as worthy citizens of India. </p>
<p> To make every child a good student, diligent and industrious, honest and truthful, just and fair in dealing with all, especially with the marginalized </p>
                           </div> 
                      </div>
                  </div>
             </section>
           <Footer/>
        </>
    );
}

export default MissionAim;
