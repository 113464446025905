 import { getVideoGallery } from '../Service/Api'; 
import { Link } from 'react-router-dom'
import Header from'../Component/Header';
import Footer from'../Component/Footer';
import LazyLoad from 'react-lazyload';
import React, { useState,useEffect } from 'react';
const VideoGallery = () => {
    useEffect(() => {
        document.title = 'Video Gallery -  Anand Bhawan School, Barabanki';  
    }, []);  
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All"); // Default to "All"
    const [yearRanges, setYearRanges] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getVideoGallery();
              console.log("video",galleryData)
            setData(galleryData);

            // Extract unique years
            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const year = new Date(item.date).getFullYear(); // Extract only the year
                        console.log(year)
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );

            // Remove the map function for generating year ranges
            const uniqueYearRanges = uniqueYears.map(year => {
                return `${year}`;
            });

            uniqueYearRanges.sort((a, b) => b - a);
            console.log("Unique Year Ranges:", uniqueYearRanges); // Log uniqueYearRanges
            setYearRanges(uniqueYearRanges);
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Filter data based on selected year
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date) {
                    const year = new Date(item.date).getFullYear();
                    return parseInt(selectedYear) === year;
                } else {
                    return false;
                }
            });
        }
        setFilteredData(filteredData);
    }, [selectedYear, data]);

   const emptyData = [
    {title:"Title"},
    {title:"Title"},
    {title:"Title"}

]
  return (
      <>
      <Header/>
      <div className="innerslide">
      <ul className="breadcrumb">
          <li><Link to="/"> Home</Link> </li> 
          <li>  Gallery</li>
           <li>Video Gallery </li>
      </ul>
  </div> 
  <section className="innerbody">
  <div className='container'>
   <div className='row'>
   <div className="col-lg-12">
   <div className="chooseyear">
       <select id="chooseddlYear"
           value={selectedYear}
           onChange={(e) => setSelectedYear(e.target.value)}
           className="yearSelection"
       >
           <option value={"All"}>All</option>
           {yearRanges.map((yr) => (
               <option key={yr} value={yr}>{yr}</option>
           ))}
       </select>

   </div>
   <div className="clr"></div>
   <div className="row">
       {filterData.length > 0 && filterData.map((item, index) => (
           <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 videosearch" key={index}>
           <div className="video-gallery">
                 <Link to={item.videoUrl} target="_blank">
                 <LazyLoad> {item?.attachments.length > 0 ? (<img src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`}  alt="Gallery" className="img-fluid"/>) : (<img src="https://webapi.entab.info/api/image/ABSB/public/Images/demo.jpg" alt="Gallery Placeholder" className="img-fluid" />)} </LazyLoad>
                      <div> 
                         <h3> {item.title} </h3>
                         <p>{item.date}</p>
                       </div> 
                 </Link>
             </div>
         </div>
       ))}
   </div>
</div>
</div>
   </div> 
</section>
<Footer/>
      </>
  )
}

export default VideoGallery
