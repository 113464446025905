import React from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom'; 
  
const Footer = () => { 
  const currentYear = new Date().getFullYear(); 
    return (
        <>
            <footer>
                 <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-lg-2 col-md-6 col-xs-12'>
                      <div className='ftr-logo'>
                      <LazyLoad> <Link to="/"><img src="https://webapi.entab.info/api/image/ABSB/public/Images/footer-logo.png" alt="Anand Bhawan School" className='img-fluid' /></Link></LazyLoad>
                      <p><i>Anand Bhawan School
Dewa Road, Barabanki,
Uttar Pradesh - 225001</i></p>
                        
                      </div>
                    </div>  
                    <div className='col-lg-3 col-md-6 col-xs-12'>
                      <div className='d-flex'>
                        <div>
                      <h5>Quick Links</h5>
                      <ul>
                      <LazyLoad>  <Link to="/AboutUs"><li><img src="https://webapi.entab.info/api/image/ABSB/public/Images/ftr-arraow.gif"/> About</li></Link></LazyLoad>
                        <LazyLoad><Link to="/Gallery"><li> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/ftr-arraow.gif"/> Gallery</li></Link></LazyLoad>
                        <LazyLoad><Link to="/Notice"><li> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/ftr-arraow.gif"/> Notice</li></Link></LazyLoad>
                        <LazyLoad><Link to="/News"><li> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/ftr-arraow.gif"/> News</li></Link></LazyLoad>
                        <LazyLoad><Link to="/ContactUs"><li> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/ftr-arraow.gif"/> Contact Us</li></Link></LazyLoad>
                      </ul>
                      </div>
                      <div>
                      <h5>Extra links</h5>
                      <ul>
                        <LazyLoad><Link to="/Pphelp"><li> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/ftr-arraow.gif"/> Portal (Help)?</li></Link></LazyLoad>
                        <LazyLoad><Link to="/ErpSoftware"><li> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/ftr-arraow.gif"/> ERP Software</li></Link></LazyLoad>
                        <LazyLoad><Link to="/Tc"><li> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/ftr-arraow.gif"/> Transfer Certificate</li></Link></LazyLoad> 
                      </ul>
                        
                      </div>
                      </div>
                      </div>                  
                    <div className='col-lg-3 col-md-6 col-xs-12'>
                      <div className='ftr-cont d-flex'>
                      <LazyLoad> <div><img src="https://webapi.entab.info/api/image/ABSB/public/Images/call-support.gif" /> </div></LazyLoad>
                        <div>
                          <p>Only During School Hours:</p>
                          <p><i>Phone no :<a href="tel:05248-222580"> 05248-222580</a></i></p>
                          <p><i>Mobile  no :<a href="tel:81279 42085"> 81279 42085</a></i></p>
                        </div>
                      </div>
                      <div className='ftr-cont d-flex'>
                      <LazyLoad><div> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/email.gif" /> </div></LazyLoad>
                        <div> 
                          <p><i>office@anandbhawanschool.com</i></p>
                          <p><i>principal@anandbhawanschool.com</i></p>
                        </div>
                      </div>
                      </div>                  
                    <div className='col-lg-4 col-md-6 col-xs-12'>
                      <div className="mobile-app">
                        <div>
                          <h3>Download Mobile App </h3><p>Applying leave of absence on behalf of your ward is easy with the app. You will get instant notification on your app of leave’s approval or rejection</p>
                          <div className='mobile-link'>
                          <LazyLoad><Link to="https://apps.apple.com/in/app/campuscare/id1034721587" target='_blank'><img src="https://webapi.entab.info/api/image/ABSB/public/Images/app-store.png" alt="Anand Bhawan School, Barabanki" /> </Link></LazyLoad>
                            <LazyLoad><Link to="https://play.google.com/store/apps/details?id=com.campuscare.entab.ui" target='_blank'><img src="https://webapi.entab.info/api/image/ABSB/public/Images/playstore.png" alt="Anand Bhawan School, Barabanki" />  </Link></LazyLoad>
                          </div>
                          <p className='code'> Use School Code : ABSBBK</p>
                        </div>
                        <div>
                         <LazyLoad> <img src='https://webapi.entab.info/api/image/ABSB/public/Images/mobile.png' alt="Anand Bhawan School, Barabanki" className='mobile-ftr' /></LazyLoad>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
            </footer>
            <div className='copyright'>
            ©️ Copyright {currentYear} Anand Bhawan School, Barabanki. All Right Reserved | Created By <a href="https://www.entab.in" target='_blank'>Entab Infotech : CampusCare®️ </a>
            </div>
        </>
    );
}

export default Footer;
