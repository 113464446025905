import React, { useEffect, useState } from "react";
import { getEvents } from "../Service/Api"; 
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
const HomeEvent = () => {
  const [data, setData] = useState([]);
  const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const handleMonth = (month) => {
      return monthsOfYear[month - 1];
  };
  useEffect(() => {
     const getData = async () => {
        const datas = await getEvents();
      
        setData(datas);
     };
     getData();
  }, []);
    var settings = { 
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,  
        arrows:false,
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 3, 
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3, 
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2,  
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1 
              }
            }
          ]
      };
      const emptyArray = [
        {id:1},
        {id:2},
        {id:3},
        {id:4}
    
      ]
  return (
     <> 
     <div className="row">
     <div className="eventbg">
     <Slider {...settings}> 
     {data && data.length > 0 ? (data.map((item, index) => {

      const date = new Date(item.date);
      const day = date.getDate();
      const month = handleMonth(date.getMonth() + 1);
      const year = date.getFullYear();

      return (
            <div className="item" key={index}>
                         <div className="homeeventbox">
                              <div className="homeeventboximg">
                                <LazyLoad>
                                {item.images.length > 0 ? (<img src={`https://webapi.entab.info/api/image/${item.images}`} className="img-fluid" alt="Anand Bhawan School, Barabanki"/>) : (<img src="https://webapi.entab.info/api/image/ABSB/public/Images/demo.jpg" className="img-fluid" alt="Anand Bhawan School, Barabanki" />) }
                                 </LazyLoad> 
                              </div>
                              <div className="homeeventboxdesc">
                              <div className="date"> {day} {month} {year} </div>
                                 <h3> {item.title}</h3>
                                 <p>{item.description}</p>
                                 <div className="d-flex justify-space-between">
                                 <p>{item.venue}  </p>
                                 <p> {item.time}</p>
                                 </div>
                               
                              </div>
                          </div>
                    </div>
                   )})) : (emptyArray.map((data) => ( 
                      <div key={data.id} className='item'>
                          <div className="galleryimg">
                          <div className="galleryimgbox">
                          <LazyLoad><img src="https://webapi.entab.info/api/image/ABSB/public/Images/events.jpg" className="img-fluid" alt="Anand Bhawan School, Barabanki" /></LazyLoad>
                            <div className="gallerydesc">                   
                              <h6>Event Title</h6>
                              <p> Stay Tunned for latest Updates</p>
                            </div>
                          </div>
                        </div>
                      </div>  
                  )))}  
     
       </Slider>
    
     </div>
     </div>
     </>
  )
}

export default HomeEvent