import { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";

const TopperX = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getTopper();
        setData(topperData);
      } catch (error) {
        console.error("Error fetching topper data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
     
    console.log("topper_data", data.length +'test');
  }, [data]);

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 },
      },
    ],
  };
  const emptyArray = [
    {id:1},
    {id:2},
    {id:3},
    {id:4}

  ]
  return (
    <>
      <div className="row">
        <Slider {...settings}>
        {data.filter((item)=>(item.class==='X')).length > 0
        ? data
            .filter((item) => item.class === "X")
            .map((item, index) => (
              <div className="item" key={index}>
                <div className="topperdiv">
                  <div className="toppertaj">
                    <LazyLoad>
                       {item.attachments && item.attachments.length > 0 ? ( <img
                        src={`https://webapi.entab.info/api/image/${item.attachments}`}
                        className="img-fluid"
                        alt={item?.name || "Topper"}
                      />) : ( <img
                        src="https://webapi.entab.info/api/image/ABSB/public/Images/topper1.jpg"
                        className="img-fluid"
                        alt="Placeholder Topper"
                      />)} 
                    </LazyLoad>
                  </div>
                  <div className="topperdesc">
                  <div className="class">
                    X
                  </div>
                  <h6>{item?.division}</h6>
                  <p className="name">
                  {item?.name}
                  </p> 
                </div> 
                </div>
              </div>
              ))
              : emptyArray.map((data) => ( 
            <div className="item" key={data.id}>
            <div className="topperdiv">
              <div className="toppertaj">
                <LazyLoad>
                  <img
                    src="https://webapi.entab.info/api/image/ABSB/public/Images/topper1.jpg"
                    className="img-fluid"
                    alt="Placeholder Topper"
                  />
                </LazyLoad>
              </div>
              <div className="topperdesc">
                <div className="class">
                  X
                </div>
                <h6>%</h6>
                <p className="name">
                  Student Name
                </p> 
              </div>
            </div>
          </div>
          ))} 
        </Slider>
      </div>
    </>
  );
};

export default TopperX;
