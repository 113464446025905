import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useNavigate hook
import { getSubGallery } from "../Service/Api";
import { Link } from 'react-router-dom'
import Header from'../Component/Header';
import Footer from'../Component/Footer'; 
import LazyLoad from 'react-lazyload';
const SubGallery = () => {
  useEffect(() => {
    document.title = 'Sub Gallery - Anand Bhawan School, Barabanki';  
}, []); 
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");
    const [data, setData] = useState("");
    const [index, setIndex] = React.useState(-1);
  
    console.log(id);
  
    useEffect(() => {
     const getData = async () => {
       try {
         let subgalleryData = await getSubGallery(id);
         console.log(subgalleryData);
         setData(subgalleryData);
       } catch (error) {
         console.log(error);
       }
     };
     getData();
   }, [id]); // Add id as a dependency
   
  
    console.log(data);
  
    const slides = data?.attachments?.map(img => ({
      src: `https://webapi.entab.info/api/image/${img}`
    }));
  return (
    <>
    <Header/>
    <div className="innerslide">
          <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>   
            <li>  Gallery</li>
            <li> Sub Gallery</li>
          </ul>
      </div>
    <section className="innerbody">
    <div className="container">
       <div className="row">
          <div className="col-md-12"> 
             <div className="fulwidth">
                <div className="sub-gallery">
                 <div className="shrink"><Link to="/Gallery"><i className="bi bi-arrow-left"></i></Link></div>  
                   <div><b>Event Date:</b> {" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}</div>
                   <div><b>Updated On:</b>  {new Date(data.date).toLocaleDateString("en-GB")}</div>
                   <div><b>Total Photos:</b> {data?.attachments?.length}</div>
                </div>
                <div className="discription">
                {data.url && (<Link to={data.url} className="downloadPic">Download Photos</Link>)}
                   <h3 className="subhead">{data.title}</h3>
                   <p><b>Description:</b>{data.description}</p>
                </div>
             </div>
            
             <div className="row zoompic">
             {data && data.attachments && data.attachments.length > 0 && (
  data.attachments.map((img, index) => (
    <div class="col-xl-3 col-lg-4 col-md-6" key={index}>
      
      <a data-magnify="gallery" data-src="" data-group="a" href={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)}>
        <LazyLoad><img  src={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)} className="img-fluid" alt={`Image ${index}`} /></LazyLoad>
      </a>
    </div>
  ))
)}

             
             </div> 
          </div>
       </div>
    </div>
    </section>
     <Footer/>
   </>
  )
}

export default SubGallery