import React, { useEffect, useState } from "react";
import { getOnlineRegistration } from "../Service/Api";
import { Link } from 'react-router-dom'
const OnlineRegistration = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
   const fetchData = async () => {
     try {
       const data = await getOnlineRegistration();
       setData(data);
     } catch (error) {
       console.error("Error fetching online registration data:", error);
     }  
   };
   fetchData();
 }, []);
 const emptyArray =[]
  return (
    <>
    <div className="OnlineR" style={{display:data.length>0?`block`:'none'}}> 
    <span className="bounce1">
     <Link to={data[0]?.url} target="_blank" className="parantlogo">  {data[0]?.title}  </Link>
     {data[0]?.attachments && data[0]?.attachments.length > 0 && ( <Link to={`https://webapi.entab.info/api/image/${data[0]?.attachments[0]}`} target="_blank" className=""> <i className="bi bi-paperclip"></i></Link>)} 
    </span>
  </div>
    </>
  )
}

export default OnlineRegistration
