import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/ABSB`); 
    return data;
} 
export const getBirthdays = async() => {    
    const { data } = await axios.get(API_URL + `/api/birthdays/ABSB`);
    return data;
} 
export const getNews = async() => { 
    const { data } = await axios.get(API_URL + `/api/news/ABSB`); 
    return data;
} 
export const getNewsInner = async() => { 
    const { data } = await axios.get(API_URL + `/api/news/all/ABSB`); 
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/ABSB`); 
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/ABSB`); 
    return data;
} 

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/ABSB`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/ABSB`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`); 
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/ABSB`,getHeader()); 
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/ABSB`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/ABSB`,getHeader()); 
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/ABSB`,getHeader());
    return data;
}

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/principalMessage/ABSB`, getHeader());
    return data;
} 

export const getMedia = async() => {
    const { data } = await axios.get(API_URL + `/api/media/ABSB`, getHeader()); 
    return data;
} 
export const getVideoGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/ABSB`, getHeader());
    return data;
} 
export const getCircular = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/ABSB`, getHeader()); 
    return data;
} 
export const getReading = async() => {
    const { data } = await axios.get(API_URL + `/api/reading/all/ABSB`, getHeader()); 
    return data;
} 
export const getTestimonial = async() => {
    const { data } = await axios.get(API_URL + `/api/testimonials/ABSB`, getHeader()); 
    return data;
} 
export const getFaculty = async() => {
    const { data } = await axios.get(API_URL + `/api/faculty/all/ABSB`, getHeader()); 
    return data;
} 

export const postTransferCertificate = async(name,admission) => { 
    const { data } = await axios.post(API_URL + `/api/tc/ABSB`,{name,admission},getHeader());
    return data;
} 
export const form1 = async (formData, header, type) => {
    try {
        const { data } = await axios.post(`${API_URL}/api/form/ABSB/${type}`, formData, header);
       
        return data;
    } catch (error) {
        console.error('Error in form submission:', error);
        if (error.response) {
            console.error('Error response data:', error.response.data); 
        }
        throw error;
    }
  };
