import React from 'react'
import { Link } from 'react-router-dom'
import Header from'../Component/Header';
import Footer from'../Component/Footer';  
import { getPrincipalMessage } from "../Service/Api"; 
import LazyLoad from 'react-lazyload';
import { useState ,useEffect } from 'react';
const DirectorMessage = () => {
  useEffect(() => {
    document.title = 'Director Message-  Anand Bhawan School, Barabanki';  
}, []); 
    const [data, setData] = useState([]);
    useEffect(() => {
     const fetchData = async () => {
       try {
        const msgdata = await getPrincipalMessage();
        const data = msgdata.filter((item) => item.category === "Director");
         setData(data);
       } catch (error) {
         console.error("Error fetching online registration data:", error);
       }  
     };
     fetchData();
   }, []);
    return (
        <>
        <Header/>
        <div className="innerslide">
              <ul className="breadcrumb">
                  <li><Link to="/"> Home</Link> </li>  
                  <li>Message</li>
                  <li>Director's Message</li>
              </ul>
          </div> 
            <section className="innerbody">
               <div className='container'>
                <div className='row'>
                {data.length > 0 && (
                    <div className="clearfix">
                       <LazyLoad> <img 
                        src={`https://webapi.entab.info/api/image/${data[0].attachments}`} 
                        className="img-fluid col-sm-12 col-md-4 float-md-end mb-3 msgimg" 
                        alt={data[0].name || 'Image'}
                      /></LazyLoad>
                      <p><div dangerouslySetInnerHTML={{ __html: data[0].message }} /></p> 
                      <p className="name">
                        {data[0].name}<span> {data[0].category} </span>
                      </p>
                    </div>
                  )}

                  {data.length === 0  && (
                    <div className="clearfix">
                       <LazyLoad> <img 
                        src="https://webapi.entab.info/api/image/ABSB/public/Images/director.jpg" 
                        className="img-fluid col-sm-12 col-md-4 float-md-end mb-3 msgimg" 
                        alt=""
                      /></LazyLoad>
                      <p>It gives me great joy to address the children and teachers and also the parents. Once a year there is an opportunity to bring to fruitfulness all the artistic gifts and talents each child has with the constant, encouraging and loving support of the teachers.</p>
                      <p>We need to be proud of our country that teaches the pupils to respect elders and elders to deal with them with care. The children are taught to touch the feet of their parents and teachers as a sign of respect and gratitude towards them. Every parent and teacher in return has God given responsibility to form the character of the children under their care.</p>
                      <p>Sadly, in the art of forming the character of the child sometimes the parents and teachers do not aim at respecting the child. Long time ago, Ralph Waldo Emerson said, “The secret of education lies in respecting the pupil.”</p>
                      <p>What does respect for the child means? It is unconditional. The child is good not because of what he/she does or has, but because of what he/she is. Every child is precious and unique. There is no second child born who is equal to the one whose character is to be formed. It is too disgraceful to appreciate and praise the child only when he/she does something or has something that others don’t have; be it high credits in the exams one has taken or things one possesses. No. This is only external. The child has to realise in the course of time that there is more beauty within oneself than in the achievements one has credited.</p>
                      <p>In a cut-throat competitive society, this is very spectacular in everyday news that failing to cope with the demands of the parents and teachers, the students attempt suicide. The suicide tendency is also seen very much in children pampered by their parents. Parents are under pressure to provide everything their children demand no matter even if they have to acquire it by fraud means. They do not want to see their children compared with other children who get these things without many efforts. Children commit suicide when their demands are not met. India is developing fast. So is the low frustration tolerance. Parents too succumb to the pressure and attempt suicide. What is wrong in committing suicide? The value of life, its preciousness is not taught to the children. Today’s children have a hyper developed technical and intellectual capacity but they have very poor emotional development. Therefore they succumb to the pressure of frustration that builds up due to competing with the rest of the world.</p>
                      <p>What is wrong with the provision of things that the children demand? Many a times the children are not in a position to analyse the difference between need and want. Not everything the children demand is a need for them. Surely they can do without it. But there is a false pride in having things to look better than the other children. The ego of the child is boosted when he/she compares herself with others and feels superior to others because of the thing she/he possesses. This is not the true self image. It is totally dependent on external things. It lasts as long as the object in possession lasts that has boosted one’s ego.</p>
                      <p>How can the teachers and parents help in building the character of children? We need to make children feel good in every situation of their life. Appreciate and encourage them all the time. Even if they do not fare well in exams tell them that they have attempted well and do not criticise them for it saying that they can never improve in life. Do not compare them with other children or their own siblings who have done well. This will lead them into inferiority complex and low self esteem. In fact it is the need of the parents and teachers to feel good when their wards do well in exams. Parents and teachers themselves are to be freed from this need satisfaction. Then only their focus will be to form the character of children.
                      </p>
                      <p>Instilling a good self-disciplined life from the beginning is important. When both parents give time to their children there is less complaint from the children. Parents and teachers need to be self-disciplined first. The children take more from the life example of their teachers and parents rather than what is taught to them in words. Once, a principal of a prestigious school narrated this incident to me. The school discipline was not to have mobile phones in the bags when the children come to school. One of the students failed to follow this rule and the principal had to reprimand him. Next day the parent of the student in question came to school and threatened the principal with dire consequences. He said to the principal that his duty was only to teach and not to interfere with the child’s personal life. Doesn’t it sound ridiculous when the parents and teachers do not abide by the discipline of the school? How will this child ever learn to be self-disciplined?</p>
                      <p>Character formation has its credit to the lap of the mother. As Hosea Ballou says,
                      </p>
                      <p>Education commences at the mother’s knee and every word spoken within the hearsay of little children tends towards the formation of character.</p>
                      <p>Respecting the pupil means to be concerned about the future of the child for which character formation from early childhood is a must. A child, whose character is not formed from the beginning, will grow up like a feeble tree that cannot stand any frustrating situation of life. This child will give in to any kind of crime in his/her adulthood.</p>
                      <p>I wish every success to the students and teachers of Anand Bhawan School, who work hard and pledge themselves to become worthy citizens by achieving a self-disciplined life in our country. God bless you all!
                      </p>
                      <p className="name">
                         <span>  Sister Lily Fernandes </span>
                      </p>
                    </div>
                  )}
                </div> 
                </div> 
             </section>
            <Footer/>
        </>
    )
}

export default DirectorMessage