import React from 'react'
import { Link } from 'react-router-dom'
import Header from'../Component/Header';
import Footer from'../Component/Footer';  
import { getPrincipalMessage } from "../Service/Api"; 
import LazyLoad from 'react-lazyload';
import { useState ,useEffect } from 'react';
const PrincipalMessage = () => {
  useEffect(() => {
    document.title = 'Principal Message-  Anand Bhawan School, Barabanki';  
}, []); 
    const [data, setData] = useState([]);
    useEffect(() => {
     const fetchData = async () => {
       try {
         const msgdata = await getPrincipalMessage();
        const data = msgdata.filter((item) => item.category === "Principal");
        
         setData(data);
       } catch (error) {
         console.error("Error fetching online registration data:", error);
       }  
     };
     fetchData();
   }, []);
    return (
        <>
        <Header/>
        <div className="innerslide">
              <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>   
                <li>  Message</li>
                <li>Principal's Message</li>
              </ul>
          </div>
            <section className="innerbody">
               <div className='container'>
                <div className='row'>
                {data.length > 0 && (
                    <div className="clearfix">
                       <LazyLoad> <img 
                        src={`https://webapi.entab.info/api/image/${data[0].attachments}`} 
                        className="img-fluid col-sm-12 col-md-4 float-md-end mb-3 msgimg" 
                        alt={data[0].name || 'Image'}
                      /></LazyLoad>
                      <p><div dangerouslySetInnerHTML={{ __html: data[0].message }} /></p> 
                      <p className="name">
                        {data[0].name}<span> {data[0].category} </span>
                      </p>
                    </div>
                  )}

                  {data.length === 0  && (
                    <div className="clearfix">
                       <LazyLoad> <img 
                        src="https://webapi.entab.info/api/image/ABSB/public/Images/principal.jpg" 
                        className="img-fluid col-sm-12 col-md-4 float-md-end mb-3 msgimg" 
                        alt=""
                      /></LazyLoad>
                      <p>I was reading an article in a magazine and it was about five women who overcame deadly situations and got on with their lives.  One of these women went through a double lung transplant, another an open heart surgery and then gave birth to twins, the third was a cancer survivor, the fourth overcame a deadly bacterial infection, and the last, while still a teenager had survived knife wounds that most people wouldn’t have survived.</p>
                      <p>This article got me thinking about how people go about their lives. Life is not a smooth ride. Some people, when they hit a bump, give up and turn around while others find ways to go over the bump or around the bump. I believe that everyone should do the latter and be like these five women and find ways to get around these bumps.</p>
                      <p>Life is full of bumps and bruises. It’s what you learn from it and what you do with it that makes you who you are. So when you reach a bump on the road called life, don’t give up. If you can’t get over it, try to go around it. Be an example for others who come after you. Parents and teachers, together let us help our students to overcome their bumps and bruises in their life instead of protecting them from it. Over protection hampers the growth of a child.</p>
                      <p>The aims and objectives of any educational institution must be to instill in the students genuine human and spiritual values, a genuine concern for the welfare of others and commitment to truth and justice and last but not least academic excellence and all round development of one’s personality to meet situation in life. To realize this we offer many co-curricular activities which help unfold hidden talents of the students. Writing stories, poems, essays, debates are activities which help students to develop the thinking and look at the things happening in the world at different angles.</p>
                      <p>The school encourages all students to participate in a variety of co-curricular activities from dance, art and drama to a variety of sports to social work to environmental conservation activities, etc. It is important for a child to explore and find their strengths in order to reach their true potential.</p>
                      <p>Academic excellence with character and personality development is our ultimate goal. The well chalked out academic and co-curricular programme enables the students to attain their full potential to develop sound work habits. The academic work is centered on highly personalized attention and supervision.</p>
                      <p>Our school value system stresses on making every child morally strong and enabling them to become a better citizen of tomorrow along with giving a multi facet exposure to the students. Quintessential values are instilled in them. The school is inching every moment towards greater heights in the fields of academics, sports and extracurricular activities and also infrastructure.</p>
                      <p>In the pursuit of excellence, I appreciate our parent fraternity for supporting the school in every aspect. I also laud the relentless efforts of our teachers for giving their best in bringing out the best in each child. I would exhort the students to be always modest, humble and disciplined, while being ready to expand the horizons of their knowledge and skills by dreaming big and working hard.</p>
                      <p>I congratulate the entire Anand Bhawan family for their dedication and hard work towards the growth of the school.</p>
                      <p>God bless you all!</p> 
                      <p className="name">
                         <span>  Sister Archana Thomas </span>
                      </p>
                    </div>
                  )}
                </div> 
                </div> 
             </section>
            <Footer/>
        </>
    )
}

export default PrincipalMessage