import React, { useState , useEffect} from 'react'; 
import { Link } from 'react-router-dom';
import { getPrincipalMessage } from "../Service/Api"; 
import LazyLoad from 'react-lazyload';
const Testimonials = () => {

  const [selectedTestimonial, setSelectedTestimonial] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const messages = await getPrincipalMessage();
      const filterData = messages.filter((item) => item.category === "Principal") 
      setData(filterData);
    };
    fetchData();
  }, []);

  const testimonials = [
    {
      name: data[0]?.name || 'Sister Archana Thomas',
      designation: data[0]?.designation || 'Principal',
      message: 'Principal’s Message',
      description: data.description ||'',
      testimonial: data[0]?.message ||   'I was reading an article in a magazine and it was about five women who overcame deadly situations and got on with their lives.  One of these women went through a double lung transplant, another an open heart surgery and then gave birth to twins, the third was a cancer survivor, the fourth overcame a deadly bacterial infection, and the last, while still a teenager had survived knife wounds that most people wouldn’t have survived. This article got me thinking about how people go about their lives. Life is not a smooth ride...',
      image: data[0]?.attachments ? `https://webapi.entab.info/api/image/${data[0].attachments}` : 'https://webapi.entab.info/api/image/ABSB/public/Images/principal.jpg',
      link: '/'
    } 
 
  ];
 

    const handleTestimonialClick = (index) => {
        setSelectedTestimonial(selectedTestimonial === index ? 0 : index);
    };

    return (
        <div className="message-container"> 
           
            {testimonials.map((testimonial, index) => (
                <div key={index} className="messageimg">
                     <LazyLoad><img
                        src={testimonial.image}
                        alt={testimonial.name}
                        onClick={() => handleTestimonialClick(index)}
                        className={selectedTestimonial === index ? 'active' : ''}
                    /> </LazyLoad>
                     <p className='text-center mt-4'>{testimonials[selectedTestimonial].name} <span>{testimonials[selectedTestimonial].designation}</span> </p>
                </div>
            ))}
        
             <div className="messagebox">
                 <div className={`${selectedTestimonial !== 0 ? 'slide-in' : 'slide-out'}`}>
                 <div className="maintitle"> <h3>{testimonials[selectedTestimonial].message}</h3></div>
                {selectedTestimonial !== null && (
                    <div>
                       <div className="messagedesc"> 
                       <p> <div dangerouslySetInnerHTML={{ __html: testimonials[selectedTestimonial].testimonial }} />  </p>
                           
                       </div> 
                        <Link to="/PrincipalMessage"> <button className='readmore'>Read More</button></Link> 
                    </div>
                )}
            </div>
            </div> 
          
        </div>
    );
};

export default Testimonials;
