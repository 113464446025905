import React,{useEffect,useState} from "react"; 
import { Link } from "react-router-dom";
import Header from'../Component/Header';  
import Footer from'../Component/Footer';   
const AboutUs = () => {    
    useEffect(() => {
    document.title = 'About Us -  Anand Bhawan School, Barabanki';  
}, []);  
    return (
        <>
        <Header/>
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>About</li>
                    <li>About Us</li>
                </ul>
            </div> 
            <section className="innerbody">
                 <div className="container"> 
                      <div className="row">
                           <div className="col-lg-12"> 
                                <p>Anand Bhawan School has been established and administered by The Institute of the Maids of the Poor, a charitable Society. It is organised, unaided English Medium School, affiliated to the Council for the Indian School Certificate Examination (ICSE), New Delhi. It is a Christian Minority Institution recognized by the Government as a Minority Educational Institution. </p> 
                                <p>The school is situated in one of the healthiest spots of Barabanki in a clean and safe locality, away from the hustle and bustle of the city and is therefore, an ideal institute for parents to whom the health of their children is a matter of prime importance. The building is imposing, specious, fitted with electricity, light, fans and surrounded by trees. The English medium section was opened in 1986. It presently runs from Nursery to class XII. </p> 
                                <p>The school is a co-educational institution where boys and girls grow up together in a healthy atmosphere of equality and mutual respect. Besides catering for the scholastic needs of the students, the school provides for the development of the entire personality of the child. Hence education is directed towards training of the pupil, intellectual, moral, emotional, physical and aesthetic endowments and such other cultural attainments as are suitable for boys and girls. </p> 
                                <p>The students are given course in moral values, which teaches them their obligation towards God, themselves, their fellowmen and their country. </p> 
                                <p>Co-curricular activities keep the students physically and mentally alert. Every step is taken to provide quality education and create a homely atmosphere in the school. The students are also instructed in forming healthy habits and cultivate values which will enable them to become contributing citizens. </p>
                             
                         </div> 
                      </div>
                  </div>
             </section>
           <Footer/>
        </>
    );
}

export default AboutUs;
