import React, { useEffect, useState } from 'react' 
import { getGallery } from '../Service/Api';
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

const Gallery = () => {
  const [data, setData] = useState([]); // State for gallery data
  const [loading, setLoading] = useState(true); // State for loading indicator

  useEffect(() => {
      const fetchData = async () => {
        try {
          const topperData1 = await getGallery();
          const topperData = topperData1.filter((item) => item.category === 'PhotoGallery'); 
         
          // Sort the data by date in descending order and then slice to get the last 6 entries
          const getcurrentData = topperData
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .slice(0, 6);
          
          setData(getcurrentData);
        } catch (error) {
          console.error("Error fetching gallery data:", error);
          // Handle error (e.g., show error message)
        } finally {
          setLoading(false); // Set loading to false after data fetching is complete
        }
      };
      fetchData();
    }, []);
  const emptyArray = [
       {id:1},
       {id:2},
       {id:3},
       {id:4},
         ]
 

  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3, 
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1, 
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, 
        }
      }
    ]
  };
  return (
    <>
      <div className="row">
      <Slider {...settings}>
      {data?.length > 0 ? data?.filter((div) => div.category === "PhotoGallery").map((item, index) => (
        <div className="item" key={index}>
              <div className="galleryimg">
                <div className="galleryimgbox">
                <Link to={`/SubGallery?id=${item._id}`}><LazyLoad>
                 {item.attachments && item.attachments.length > 0 ? (<img src={`https://webapi.entab.info/api/image/${item.attachments
                 [0]}`} className="img-fluid" alt="Anand Bhawan School, Barabanki" />) : (<img src="https://webapi.entab.info/api/image/ABSB/public/Images/demo.jpg" className="img-fluid" alt="Anand Bhawan School, Barabanki" />) }
                  </LazyLoad></Link>
                  <div className="gallerydesc">
                    <h6>{item.title}</h6>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            </div> 
            )):emptyArray.map((data) => ( 
              <div key={data.id} className='item'>
                  <div className="galleryimg">
                  <div className="galleryimgbox">
                  <LazyLoad><img src="https://webapi.entab.info/api/image/ABSB/public/Images/demo.jpg" className="img-fluid" alt="Anand Bhawan School, Barabanki" /></LazyLoad>
                    <div className="gallerydesc">                   
                      <h6>Gallery Title</h6>
                      <p>Photo Information and details</p>
                    </div>
                  </div>
                </div>
              </div>  
          ))}  
          </Slider> 
      </div>
    </>
  )
}

export default Gallery