import { useEffect, useState } from "react";
import { getNewsInner } from "../Service/Api";
import { Link } from 'react-router-dom'
import Header from'../Component/Header';
import Footer from'../Component/Footer'; 
const News = () => {
   useEffect(() => {
      document.title = 'News -  Anand Bhawan School, Barabanki';  
  }, []); 
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState();
   const [selectedMonth, setSelectedMonth] = useState("All");
   const [monthOptions, setMonthOptions] = useState(["All"]);
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");

   useEffect(() => {
      const currentYear = new Date().getFullYear();
      const Year = `${currentYear}`;
      setSelectedYear(Year);
      console.log("get current Year", Year);
   }, []);
   useEffect(() => {
      const fetchData = async () => {
         const galleryData = await getNewsInner();
         setData(galleryData);


         const uniqueYears = Array.from(
            new Set(galleryData.flatMap((item) => {
               if (item.fromDate) {
                  const year = new Date(item.fromDate).getFullYear();
                  return [year];
               } else {
                  return [];
               }
            }))
         );
         const uniqueYearRanges = uniqueYears.map(year => `${year}`);
         uniqueYearRanges.sort((a, b) => b - a);
         setYearRanges(uniqueYearRanges);

         const uniquemonth = Array.from(
            new Set(galleryData.flatMap((item) => {
               if (item.fromDate) {
                  const month = new Date(item.fromDate).getMonth() + 1;
                  return [month];
               } else {
                  return [];
               }
            }))
         );
         const uniqueMonths = uniquemonth.map(month => `${month}`);
         uniqueMonths.sort((a, b) => b - a);
         setMonthOptions(uniqueMonths);
      };
      fetchData();
   }, []);
   useEffect(() => {
      let filteredData = data;
    
      // Filter data by selected year and month
      if (selectedYear !== "All") {
        filteredData = filteredData.filter((item) => {
          if (item.fromDate && item.fromDate.trim() !== "") {
            const year = new Date(item.fromDate).getFullYear();
            const month = new Date(item.fromDate).getMonth() + 1;
            return (
              parseInt(selectedYear) === year &&
              (selectedMonth === "All" || parseInt(selectedMonth) === month)
            );
          } else {
            return false;
          }
        });
      }
    
      // Check if no data is available for the selected year, fallback to previous year or all years
      if (filteredData.length === 0) {
        const fallbackYear = selectedYear !== "All" ? parseInt(selectedYear) - 1 : "All";
    
        if (fallbackYear !== "All") {
          filteredData = data.filter((item) => {
            if (item.fromDate && item.fromDate.trim() !== "") {
              const year = new Date(item.fromDate).getFullYear();
              const month = new Date(item.fromDate).getMonth() + 1;
              return (
                fallbackYear === year &&
                (selectedMonth === "All" || parseInt(selectedMonth) === month)
              );
            } else {
              return false;
            }
          });
        }
    
        // If still no data, show all years
        if (filteredData.length === 0) {
          setSelectedYear("All");
          filteredData = data.filter((item) => {
            if (item.fromDate && item.fromDate.trim() !== "") {
              const month = new Date(item.fromDate).getMonth() + 1;
              return (
                selectedMonth === "All" || parseInt(selectedMonth) === month
              );
            } else {
              return false;
            }
          });
        }
      }
    
      // Filter by search query
      if (searchQuery) {
        filteredData = filteredData.filter((item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    
      setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);
    

   return (
      <>
      <Header/>
      <div className="innerslide">
               <ul className="breadcrumb">
                  <li><Link to="/"> Home</Link> </li>   
                  <li>News</li>
               </ul>
         </div>
         <section className="innerbody">
            <div className="container">

               <div className="row tabs-dynamic">
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                     <div className="count-val">
                        <p>Total Count:{filterData.length} </p>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                     <div className="month-selection">

                        <select
                           value={selectedYear}
                           onChange={(e) => setSelectedYear(e.target.value)}
                           className="yearSelection">
                           <option value={"All"}>All</option>
                           {yearRanges.map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                           ))}
                        </select>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                     <div className="month-selection">
                        <select
                           value={selectedMonth}
                           onChange={(e) => setSelectedMonth(e.target.value)}
                           className="monthSelect"
                        ><option value={"All"}>All</option>
                           {monthOptions.map((month, index) => (
                              <option key={index} value={month}>{month}</option>
                           ))}
                        </select>
                     </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                     <div className="searchBox">
                        <input
                           type="text"
                           id="myInput"
                           name="name"
                           value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value)}
                           placeholder="Search Here.."
                        />
                     </div>
                  </div>
               </div>
               <div className="row years year2023 showYear">

                  {filterData.length > 0 &&
                     filterData.map((item, index) => (
                        <div className="col-md-12 newsCount" key={index}>

                           <div className="inr-news-blk">
                           <h6 className="date">
  {new Date(item.fromDate).getDate()}{" "}
  <span>{new Date(item.fromDate).toLocaleString('default', { month: 'short' })}</span>
</h6>
                              <div className="inr-news-content">
                                 <h3>{item.title}</h3>
                                 <p>{item.description}</p>
                                 <h6 className="loc">
                                    {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank"> <span> View Attachment <i className="bi bi-arrow-right"></i> </span></Link>)}
                                 </h6>
                              </div>
                           </div>

                        </div>
                     ))}

               </div>
            </div>
         </section>
          <Footer/>
      </>
   )
}

export default News