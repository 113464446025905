
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import Home from'./Pages/Home'  
import ErpSoftware from'./Pages/ErpSoftware'  
import Events from'./Pages/Events'  
import Gallery from'./Pages/Gallery'  
import PrincipalMessage from'./Pages/PrincipalMessage'  
import SubGallery from'./Pages/SubGallery'  
import TopperX from'./Pages/TopperX'  
import TopperXII from'./Pages/TopperXII'  
import News from'./Pages/News'  
import Tc from'./Pages/Tc'  
import DirectorMessage from'./Pages/DirectorMessage'  
import VideoGallery from'./Pages/VideoGallery' 
import ContactUs from'./Pages/ContactUs' 
import AboutUs from'./Pages/AboutUs' 
import EthosValue from'./Pages/EthosValue' 
import MissionAim from'./Pages/MissionAim' 
import Notice from'./Pages/Notice' 
import Pphelp from'./Pages/Pphelp' 

function App() { 
  return (
      <>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home/>} /> 
      <Route path="/ErpSoftware" element={<ErpSoftware/>} /> 
      <Route path="/Events" element={<Events/>} /> 
      <Route path="/Gallery" element={<Gallery/>} /> 
      <Route path="/News" element={<News/>} /> 
      <Route path="/PrincipalMessage" element={<PrincipalMessage/>} /> 
      <Route path="/SubGallery" element={<SubGallery/>} /> 
      <Route path="/TopperX" element={<TopperX/>} /> 
      <Route path="/TopperXII" element={<TopperXII/>} /> 
      <Route path="/Tc" element={<Tc/>} /> 
       <Route path="/DirectorMessage" element={<DirectorMessage/>} /> 
       <Route path="/VideoGallery" element={<VideoGallery/>} /> 
       <Route path="/ContactUs" element={<ContactUs/>} /> 
       <Route path="/AboutUs" element={<AboutUs/>} /> 
       <Route path="/EthosValue" element={<EthosValue/>} /> 
      <Route path="/MissionAim" element={<MissionAim/>} />
      <Route path="/Notice" element={<Notice/>} />
      <Route path="/Pphelp" element={<Pphelp/>} />
      </Routes>
     </BrowserRouter>
      </>
  )
}

export default App;
