import React,{useEffect} from "react"; 
import { Link } from "react-router-dom";
import Header from'../Component/Header';  
import Footer from'../Component/Footer';   
const EthosValue = () => {  
             useEffect(() => {
    document.title = 'Ethos & Value -  Anand Bhawan School, Barabanki';  
}, []); 
    return (
        <>
        <Header/>
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>About</li>
                    <li>Ethos & Value</li>
                </ul>
            </div> 
            <section className="innerbody">
                 <div className="container"> 
                      <div className="row">
                           <div className="col-lg-12">
                                <h5>Educational Ethos of Anand Bhawan School</h5>
                                  <ul className="mainlist">
                                    <li>To be always a channel of God’s Forgiveness, peace and love to all creations of God especially human beings. </li>
                                    <li>To keep oneself and one’s surroundings clean. It is the reflection of one’s inner self. </li>
                                    <li>To pray to the Almighty to give us the strength to achieve the things we can and the serenity to accept what we cannot. </li>
                                    <li>To struggle against all odds; the greatness is not in achieving the easy and the ordinary; it is in achieving the seemingly impossible that greatness lies; that history is made by the courageous who dared to question and to challenge the ordinary and the easy. </li>
                                    <li>Never to disown, hurt or oppress the poor, nor to bend one’s knees before insolent might. </li>
                                    <li>To learn to give and give generously till it pains; but let not the left hand know what the right hand is giving. </li>
                                    <li>Not to waste one’s time. One life is not enough to unravel the mysteries of life and of this universe. </li>
                                    <li>To appreciate the beauty and goodness in the universe, within oneself and in others. </li>
                                    <li>That a good deed sets in motion a chain of other good deeds, just as a warm smile will not fail to reflect another warm smile. </li>
                                    <li>That every human being is basically good; if anyone is ‘bad’ to you, it is not your fault; nor is it his fault; he may have been treated badly by others. May your goodness to him bring back his own goodness. Always remember an eye for an eye and a tooth for a tooth has never brought anybody peace. But when one strikes you on one cheek, showing him the other too has brought reconciliation, conversion and repentance. </li> 
                                    <li> Never to curse darkness, but to light a candle. </li>
                                    <li> This great Universe and all it contains is the creation of the Almighty. Hence do not pollute nature, but keep the ecological balance. </li>
                                    <li> Not to be afraid to go against the current. Sacrifice of one’s life for a worthy cause is better than a long life that no one has reasons to remember. </li>
                                    <li> That each individual is unique and each one must find his own unique path in life to success and fulfillment. Dare to take the path less travelled, for that will make all the difference in one’s life. </li>
                                    <li> To be always on the search to discover, to explore; the possibilities and opportunities are unlimited. </li>
                                    <li> To play well the game of life. Remember the important thing is not to win but to take part, just as the important thing in life is not the triumph but the struggle. The essential thing is not to have conquered but to have fought well. </li>
                                    <li> To be prepared to pay the price of greatness and achievement: Jesus Christ was crucified and killed before he rose from the dead to eternal life; Mahatma Gandhi faced the bullet before he became the Father of the Nation. </li>
                                   </ul>

                                   <div className="ethos">
                                        <p> Lives of great men all remind us, <br/>
                                        We can make our lives sublime, <br/>
                                        And departing leave behind us, <br/>
                                        Footprints on the sands of time. <br/>
                                        Footprints that perhaps another, <br/>
                                        Sailing over lives solemn mane <br/>
                                        A forlorn and shipwrecked brother, <br/>
                                        Seeing shall take heart again. </p>
                                   </div>
                           </div> 
                      </div>
                  </div>
             </section>
           <Footer/>
        </>
    );
}

export default EthosValue;
