import React from 'react'
const HomeSlider = () => {
  return (
   <>
   <div className="slider-sec"> 
   <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel"> 
  
<div className="carousel-inner">  
<div className="carousel-item active" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/slide1.jpg" className="d-block w-100" alt="Anand Bhawan School, Barabanki"/> </div>
<div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/slide5.jpg" className="d-block w-100" alt="Anand Bhawan School, Barabanki"/> </div>  
<div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/slide6.jpg" className="d-block w-100" alt="Anand Bhawan School, Barabanki"/> </div>  
<div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/slide7.jpg" className="d-block w-100" alt="Anand Bhawan School, Barabanki"/> </div> 
<div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/slide2.jpg" className="d-block w-100" alt="Anand Bhawan School, Barabanki"/> </div>  
<div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/slide3.jpg" className="d-block w-100" alt="Anand Bhawan School, Barabanki"/> </div>  
<div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/slide4.jpg" className="d-block w-100" alt="Anand Bhawan School, Barabanki"/> </div>  

   </div> <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button> <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button> 
   </div> 
      
   </div>
   
   </>
  )
}

export default HomeSlider
