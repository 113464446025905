import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
import { getNews } from '../Service/Api';
const News = () => {
  const [data, setData] = useState([]); // State for gallery data
  const [loading, setLoading] = useState(true); // State for loading indicator 
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getNews();
        setData(topperData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
        // Handle error (e.g., show error message)
      } finally {
        setLoading(false); // Set loading to false after data fetching is complete
      }
    };
    fetchData();
  }, []);
 
  return (
    <>
      <div className="newsbg">
        <marquee onMouseOver={(event) => event.currentTarget.stop()} direction="up" onMouseOut={(event) => event.currentTarget.start()}>
          <ul className="newsboxlist">
             {data.length > 0 ? (data.map((item, index) => {
                   const date = new Date(item.fromDate);
                    const day = date.getDate();
                    const mon = monthNames[date.getMonth()];
                    
              return(
              <li key={index}>
                <div className="newsbox">
                  <div className='icon'>
                    <LazyLoad><img src='https://webapi.entab.info/api/image/ABSB/public/Images/arrow.png' className='img-fluid' /></LazyLoad>
                  </div>
                  <div className="newsdesc">
                    <h6>{item.title}</h6>
                    <p>{item.description}</p>
                    {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image/${item.attachments}`} target="_blank"><i className="bi bi-paperclip"></i></Link>)}
                  </div>
                  <div className='news-date'>
                       {day} <span>{mon}</span>
                  </div>
                
                </div>
              </li>
            )})
            ) : (
              <li>
                <div className="newsbox">
                <div className='icon'>
                    <LazyLoad><img src='https://webapi.entab.info/api/image/ABSB/public/Images/arrow.png' className='img-fluid' /></LazyLoad>
                  </div>
                  <div className="newsdesc">
                    <h6>News Title</h6>
                    <p>Stay Tunned For More Updates</p>
                    <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                  </div>
                <div className='news-date'>
                   DD <span>MM </span>
                  </div>
                  </div>
              </li>
            )}  
           
          </ul>
        </marquee>
        <Link to="/News"><button className='readmore m-auto d-block mt-2'> View All</button></Link>
      </div>
    </>
  )
}

export default News
