import React, {useEffect } from 'react'; 
import { Link } from 'react-router-dom'
import Header from'../Component/Header';
import Footer from'../Component/Footer';
import LazyLoad from 'react-lazyload';
const ErpSoftware = () => {
    useEffect(() => {
        document.title = 'Erp Software -  Anand Bhawan School, Barabanki';  
    }, []); 
   return (
        <>
        <Header/>
        <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>ErpSoftware</li>
                </ul>
            </div> 
            <section className="innerbody">
                <div className="container">
                    <div className="erpbox">
                        <div className="erpboxdesc">
                            <p class="details"> Entab Infotech Pvt Ltd., a company formed in 2000 and serving the school with automation technologies has evolved out as the best service provider through their advanced <Link to="/https://www.entab.in/school-management-erp.html"> <b>ERP Software:</b></Link> 'CampusCare®' and communication tools. We are also proud to be associated with them for managing the data of students, exam, fees, staff and  their accounts etc in addition to the <Link to="/https://www.entab.in/school-communication-portals.html"> <b> communication portal</b></Link> and
                                <Link to="/https://www.entab.in/mobile-apps.html">
                                    <b> mobile apps</b>
                                </Link>.<br />
                                <br />
                                The entire system is strengthening the academic of the students and preparing responsible staff and students. Together the reputation of the school grows higher. We value the relation with <Link to="/https://www.entab.in/"> <b>Entab: CampusCare®</b></Link> and look forward to receive the much more in technological front. </p>
                            <div className="erpboxdescpopup">
                                <div className="erppopup">
                                    <h4 className="imgheading"><Link to="https://www.entab.in/school-management-erp.html"> Manage Data</Link></h4>
                                    <LazyLoad><img id="myImg" src="https://webapi.entab.info/api/image/ABSB/public/Images/Manage-Data.gif" alt="School Management System, Management ERP Software" className="animation center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop" /></LazyLoad>
                                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                   <img src="https://webapi.entab.info/api/image/ABSB/public/Images/Manage-Data.gif" alt="School Management System, Management ERP Software" className="img-fluid" /> 
                                                   
                                                    <h5>School Management System, Management ERP Software</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="erppopup">
                                    <h4 className="imgheading"><Link to="https://www.entab.in"> Parent Portal</Link></h4>
                                    <LazyLoad><img id="myImg1" src="https://webapi.entab.info/api/image/ABSB/public/Images/Parent-Portal.gif" alt="School Management System, Management ERP Software" className="animation center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop0" /></LazyLoad>
                                    <div className="modal fade" id="staticBackdrop0" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                <img src="https://webapi.entab.info/api/image/ABSB/public/Images/Parent-Portal.gif" alt="School Management System, Management ERP Software" className="img-fluid" />
                                                    <h5>Entab Parent Portal, School Software</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="erppopup">
                                <h4 className="imgheading"><Link to="https://www.entab.in"> Communication Portal</Link></h4>
                                <LazyLoad><img id="myImg2" src="https://webapi.entab.info/api/image/ABSB/public/Images/communication.gif" alt="School Communication Portal" className="animation  center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" /></LazyLoad>
                                <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                            <img src="https://webapi.entab.info/api/image/ABSB/public/Images/communication.gif" alt="School Communication Portal" className="img-fluid" />  
                                                <h5>School Communication Portal</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                </div>
                                
                            </div>
                        </div>
                        <div className="erpboximg">
                            <h4 className="imgheading"><Link to="/https://www.entab.in">CampusCare</Link></h4>
                            <center><Link to="https://www.entab.in/mobile-apps.html"><LazyLoad> <img src="https://webapi.entab.info/api/image/ABSB/public/Images/Mobile-slides.gif" className="img-fluid" alt="School ERP Software, Mobile App, School Management Software, Software for School" /></LazyLoad>
                            </Link> </center>
                        </div>
                    </div>
                </div>
             </section>
            <Footer/>
        </>
    )
}

export default ErpSoftware