import React,{useEffect} from 'react'
import Popup from '../Component/Popup'
import Header from '../Component/Header'
import HomeSlider from '../Component/HomeSlider'
import Campuscare from '../Component/Campuscare'
import OnlineRegistration from '../Component/OnlineRegistration'
import Footer from '../Component/Footer'
import Message from '../Component/Message'
import News from '../Component/News'
import Events from '../Component/Events'
import TopperX from '../Component/TopperX'
import TopperXII from '../Component/TopperXII'
import Gallery from '../Component/Gallery'
 import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom'
const Home = () => {
     useEffect(() => {
      document.title = 'Home -  Anand Bhawan School, Barabanki';  
  }, []); 
  return (
    <>
     <div className="whatsappicn">
         <Link to="https://web.whatsapp.com/send?l=en&phone=+918127942085&text=Contact%20Us" target="_blank"> <LazyLoad><img src="https://webapi.entab.info/api/image/ABSB/public/Images/whatsapp.png" className='img-fluid' /></LazyLoad></Link>
     </div>
        <Campuscare />  
      <Popup />
      <Header />

      <OnlineRegistration />
      <HomeSlider />
      <div className="aboutmidsec">
        <div className='box'>
          

            <h3>Vision</h3>
            <p>"Educate to Empower" -- this phrase aptly sums up our mission and vision. Anand Bhawan School has a long tradition of providing quality education to its students.</p>
       
        </div>
        <div className='box'>
         
            <h3>Mission</h3>
            <p>Imparting quality education, We strive to build Effective, efficient, responsible Individuals to transform the Society.</p>
          
        </div>
      </div>
      <div className='about'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6'>
              <LazyLoad><img src="https://webapi.entab.info/api/image/ABSB/public/Images/about.png" className='img-fluid' /></LazyLoad>
            </div>
            <div className='col-xl-6'>
              <div className='title'>
                <p>ABOUT US</p>
                <h1>Anand bhawan school</h1>
              </div>
              <p>Anand Bhawan School has been established and administered by The Institute of the Maids of the Poor, a charitable Society. It is organised, unaided English Medium School, affiliated to the Council for the Indian School Certificate Examination (ICSE), New Delhi. It is a Christian Minority Institution recognized by the Government as a Minority Educational Institution.</p>
              <p>The school is situated in one of the healthiest spots of Barabanki in a clean and safe locality, away from the hustle and bustle of the city and is therefore, an ideal institute for parents to whom the health of their children is a matter of prime importance. The building is imposing, specious, fitted with electricity, light, fans and surrounded by trees. The English medium section was opened in 1986. It presently runs from Nursery to class XII.</p>
              <Link to="/AboutUs"><button className='readmore'> READ MORE</button></Link>
            </div>
          </div>
        </div>
      </div>
      <div className='message padding-section'>
        <LazyLoad><img src="https://webapi.entab.info/api/image/ABSB/public/Images/chat.png" className='msg-anim1' /></LazyLoad>
        <LazyLoad><img src="https://webapi.entab.info/api/image/ABSB/public/Images/message.gif" className='msg-anim2' /></LazyLoad>
        <LazyLoad><img src="https://webapi.entab.info/api/image/ABSB/public/Images/announce.gif" className='msg-anim3' /></LazyLoad>
        <LazyLoad><img src="https://webapi.entab.info/api/image/ABSB/public/Images/newsgif.gif" className='msg-anim4' /></LazyLoad>
        
        <div className='container'>
          <div className='row'>
            <div className='col-xl-8'>
              <div className='message-blk'>
                <div className='title text-center mb-4'>
                  <p>MESSAGE SECTION</p>
                  <h2>From the Desk Of</h2>
                </div>
                <Message />
              </div>
            </div>
            <div className='col-xl-4'>
              <div className='news'>
              <div className='title text-center mb-4'>
                  <p>NEWS SECTION</p>
                  <h2>Latest news</h2>
                </div>
                <News />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="padding-section">
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
          <div className='title text-center mb-4'>
                <p>EVENTS SECTION</p>
                <h2>Upcoming Events</h2>
              </div>
              <Events />
              <Link to="/Events"><button className="readmore m-auto d-block mt-4">View All</button></Link>
          </div> 
        </div>
      </div>
      </div>
      <div className="padding-section">
      <div className='container'>
        <div className='row'> 
          <div className='col-xl-12 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-5 mt-xs-5'>
          <div className='title text-center mb-4'>
                <p>TOPPERS SECTION</p>
                <h2>OUR ACHIEVERS</h2>
              </div>
              <div className='row'>
                
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                  <TopperXII />
                  <Link to="/TopperXII"><button className="readmore m-auto d-block mt-4">View All</button></Link>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                  <TopperX />
                  <Link to="/TopperX"><button className="readmore m-auto d-block mt-4">View All</button></Link>
                </div>
              </div>
              
          </div>
        </div>
      </div>
      </div>
      <div className="padding-section">
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
          <div className='title text-center mb-4'>
                  <p>Media</p>
                  <h2>Photo Gallery</h2>
                </div>
          </div>
          <div className='col-md-12'>
            <Gallery />
            <Link to="/Gallery"><button className="readmore m-auto d-block mt-4">View All</button></Link>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>

  )
}


  export default Home
